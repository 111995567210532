import * as React from 'react';
import Story from 'styleguide/components/Story/Story';

import img from 'assets/images/stories/make-something-swift.jpg';

const DescriptionBlock = () => (
  <div>
    <p>Waste no time and hit critical deadlines.</p>
    With facilities on both coasts, 24/7 production, and custom shipping options, nobody delivers product
    faster.
  </div>
);

const StorySwift = () => (
  <Story
    intro="Make Something"
    title="Swift"
    description={<DescriptionBlock />}
    imageUrlMobile={img}
    imageUrlDesktop={img}
    direction="left"
    imageTitle="Make Something Swift"
    imageAlt="Make something swift"
  />
);

export default StorySwift;
